import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Tags from './tags';

const PostList = ({ posts }) => {
  const PostList = posts.map(({ frontmatter, fields, excerpt, timeToRead }) => {
    const { title, tags, date, description } = frontmatter;
    const { slug } = fields;

    return (
      <PostListItem
        key={slug}
        tags={tags}
        title={title}
        date={date}
        slug={slug}
        timeToRead={timeToRead}
        description={description}
        excerpt={excerpt}
      />
    );
  });

  return <StyledPostList>{PostList}</StyledPostList>;
};


export default PostList;

export const DevListItem = ({
  name,
  title,
  date,
  timeToRead,
  tags,
  excerpt,
  description,
  slug,
  image,
  mobileImage
}) => {
  return (
    <StyledPostListItem>
      <FlexItem>
        <Tags tags={tags} />
        <PostListTitle>
          <StyledLink href={slug} target="new">{name}</StyledLink>
        </PostListTitle>
        <Flex>
          {image && <FlexItem>
            <img src={image.toLowerCase()} alt={`Desktop preview of ${slug} - ${title}`} />
          </FlexItem>}
          {mobileImage && <FlexItem>
            <img src={mobileImage.toLowerCase()} style={{ margin: '1ch' }} alt={`Mobile Preview of ${slug} - ${title}`} />
          </FlexItem>}
        </Flex>
        <SubTitleContainer>
          {title}
        </SubTitleContainer>
        <PostListExcerpt
          dangerouslySetInnerHTML={{
            __html: description || excerpt,
          }}
        />

      </FlexItem>
    </StyledPostListItem>
  );
};


export const PostListItem = ({
  title,
  date,
  timeToRead,
  tags,
  excerpt,
  description,
  slug,
  image
}) => {
  return (
    <StyledPostListItem>
      {image && <FlexItem>
        <img src={image} style={{ width: "clamp(100px, 200px, 500px" }} alt={`Preview of ${slug}`} />
      </FlexItem>}
      <FlexItem>
        <Tags tags={tags} />
        <PostListTitle>
          {slug.includes(':') ? <StyledLink href={slug} target="new">{title}</StyledLink> : <StyledLink to={slug}>{title}</StyledLink>}
        </PostListTitle>

        <PostListExcerpt
          dangerouslySetInnerHTML={{
            __html: description || excerpt,
          }}
        />
        <PostListMeta>
          <span>{date}</span>

          {timeToRead && <span>{timeToRead} mins</span>}
        </PostListMeta>
      </FlexItem>
    </StyledPostListItem>
  );
};

const StyledLink = styled(Link)`
a,
a:visited {
  color: black;
  text-decoration: underline;

}

a{
  text-decoration-color: #5a385a66;
}

a:visited {
  text-decoration-color: #5a385a33;
}

a:hover,
a:focus {
  background-color: #3c073c;
  color: white;
  text-decoration: underline;
}

`

export const StyledPostList = styled.ul`
  padding: 0;
  list-style: none;
  display: grid;
  justify-items: center;
  grid-gap: var(--size-600);
  grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr));

  @media screen and (max-width: 500px) {
    & {
      display: block;
    }
  }
`;

export const StyledDevList = styled(StyledPostList)`
  

  grid-template-columns: repeat(auto-fit, minmax(45ch, 1fr));

  @media screen and (max-width: 500px) {
    & {
      display: block;
    }
  }
`;

const StyledPostListItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  width: 100%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  @media screen and (max-width: 500px) {
    & {
      margin-top: var(--size-600);
    }
  }
`;

const PostListTitle = styled.h2`
  line-height: 1.4;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-size: var(--size-600);
  font-weight: 700;

  & a {
    text-decoration: none;
    color: #3c073c;
  }

  & a::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & a:hover,
  & a:focus {
  background-color: #3c073c;
  color: white;
  }
`;

const PostListExcerpt = styled.p`
  margin-top: auto;
  font-size: var(--size-400);
`;

const PostListMeta = styled.div`
  margin-top: 2rem;

  font-size: var(--size-300);
  display: flex;
  justify-content: space-between;
`;
const Flex = styled.div`
display: flex;
align-items: center;
`
const FlexItem = styled.div`
flex-direction: column;
`

const SubTitleContainer = styled.div`
  margin: 1rem 0;
`